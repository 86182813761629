import { day } from '@pt/utilities';

export default function () {
  let semanalHeaders = [];
  let mensualHeaders = [];
  let totalUf = [];

  const formatHeadersFertilization = (tabla, tabla_mensual, total_uf) => {
    semanalHeaders = [];
    mensualHeaders = [];
    totalUf = [];

    tabla.headers.forEach(element => {
      semanalHeaders.push(element.text);
    });
    tabla_mensual.headers.forEach(element => {
      mensualHeaders.push(element.text);
    });
    total_uf.headers.forEach(element => {
      totalUf.push(element.text);
    });
  };
  const getDataTableToExportFertilization = (data, isExtensivoOrSecano) => {
    let tablesData = [];
    if (!isExtensivoOrSecano) {
      tablesData = [
        {
          data: data.tabla.data,
          header: getHeadersToExport('semanal'),
          sheetTitle: 'Plan abonado semanal',
          pdfColumnTitle: 'Plan abonado semanal'
        },
        {
          data: data.tabla_mensual.data,
          header: getHeadersToExport('mensual'),
          sheetTitle: 'Plan abonado mensual',
          pdfColumnTitle: 'Plan abonado mensual'
        },
        {
          data: data.tabla_uf.data,
          header: getHeadersToExport('unidadesSemanales'),
          sheetTitle: 'Unidades F. semanales',
          pdfColumnTitle: 'Unidades F. semanales'
        },
        {
          data: data.tabla_uf_mensual.data,
          header: getHeadersToExport('unidadesMensuales'),
          sheetTitle: 'Unidades F. mensuales',
          pdfColumnTitle: ''
        },
        {
          data: data.reporte.data,
          header: getHeadersToExport('reporteNecesidadesTeoricas'),
          sheetTitle: 'Necesidades teóricas',
          pdfColumnTitle: 'Necesidades teóricas'
        },
        {
          data: data.necesidades_totales.data,
          header: getHeadersToExport('reporteNecesidadesPrácticas'),
          sheetTitle: 'Necesidades prácticas',
          pdfColumnTitle: 'Necesidades prácticas'
        },
        {
          data: data.total_uf.data,
          header: getHeadersToExport('totalUf'),
          sheetTitle: 'Totales unidades',
          pdfColumnTitle: 'Totales unidades'
        },
        {
          data: data.total_abonos.data,
          header: getHeadersToExport('total'),
          sheetTitle: 'Totales',
          pdfColumnTitle: 'Totales'
        }
      ];
    } else {
      tablesData = [
        {
          data: data.tabla.data,
          header: getHeadersToExport('semanal'),
          sheetTitle: 'Plan abonado semanal',
          pdfColumnTitle: 'Plan abonado semanal'
        },
        {
          data: data.reporte.data,
          header: getHeadersToExport('reporteNecesidadesTeoricas'),
          sheetTitle: 'Necesidades teóricas',
          pdfColumnTitle: 'Necesidades teóricas'
        },
        {
          data: data.necesidades_totales.data,
          header: getHeadersToExport('reporteNecesidadesPrácticas'),
          sheetTitle: 'Necesidades prácticas',
          pdfColumnTitle: 'Necesidades prácticas'
        }
      ];
    }

    return tablesData;
  };

  const getHeadersToExport = table => {
    const headers = {
      semanal: [semanalHeaders],
      mensual: [mensualHeaders],
      totalUf: [totalUf],
      unidadesSemanales: [
        [
          'Semana',
          'Inicio',
          'Fin',
          'U.F. Nitrógeno',
          'U.F. Fósforo',
          'U.F. Potasio',
          'U.F. Magnesio',
          'U.F. Calcio',
          'U.F. Azufre'
        ]
      ],
      unidadesMensuales: [
        [
          'Mes',
          'U.F. Nitrógeno',
          'U.F. Fósforo',
          'U.F. Potasio',
          'U.F. Magnesio',
          'U.F. Calcio',
          'U.F. Azufre'
        ]
      ],
      reporteNecesidadesTeoricas: [['Balance (kg/ha)', 'N', 'P₂O₅', 'K₂O', 'MgO', 'CaO', 'SO₃']],
      reporteNecesidadesPrácticas: [['Balance (kg)', 'N', 'P₂O₅', 'K₂O', 'MgO', 'CaO', 'SO₃']],
      total: [['Abono', 'Cantidad']]
    };
    return headers[table];
  };
  const getSummaryFertilization = (generalData, downloadDate) => {
    return [
      {
        col1: `Explotación: ${generalData.exploitation.nombre} `,
        col2: `Fecha informe: ${downloadDate} `
      },
      { col1: `Finca:  ${generalData.farm.nombre}` },
      {
        col1: `Cultivo: ${generalData.crop.nombre} `,
        col2: `Sistema de cultivo:  ${generalData.crop.sistema_cultivo_nombre}`
      },
      { col1: `Sistema de riego:  ${generalData.crop.tipo_riego_nombre}` },
      {
        col1:
          generalData.crop.grupo_cultivo_id === 2
            ? `Edad: ${generalData.crop.edad} `
            : `Días cultivo: ${getCultivationDays(generalData.crop)} `,
        col2:
          generalData.crop.grupo_cultivo_id === 2
            ? ''
            : `Trasplante/Emergencia:  ${day.getFormattedDate(
                generalData.crop.fecha_siembra,
                'DD-MM-YYYY'
              )}`
      }
    ];
  };
  const getCultivationDays = crop => {
    const seedingDate = day.getFormattedDate(crop.fecha_siembra, 'YYYY-MM-DD');
    return day.getDaysBetween(day.getToday('YYYY-MM-DD'), seedingDate) + 1;
  };
  return {
    formatHeadersFertilization,
    getDataTableToExportFertilization,
    getHeadersToExport,
    getSummaryFertilization
  };
}
