import { env, enums } from '@pt/env-config';
import { day, helpers } from '@pt/utilities';
import globalStorage from '../global-storage';

const parseDate = value => {
  return value ? day.getFormattedDate(value, 'YYYY-MM-DD') : day.getToday('YYYY-MM-DD');
};
const differenceBetweenDates = (date1, date2) => {
  return day.getDaysBetween(parseDate(date1), parseDate(date2));
};
const thereIsDataToLoad = crop => {
  return differenceBetweenDates(crop.fecha_siembra, new Date()) <= 3;
};
const formattedTime = (value, result, toExport = false) => {
  const timestamp = value / 1000000000;
  let hours = null;
  let minutes = null;
  if (timestamp < 0) {
    hours = Math.ceil(timestamp / 60 / 60);
    minutes = Math.ceil(timestamp / 60) - hours * 60;
  } else {
    hours = Math.floor(timestamp / 60 / 60);
    minutes = Math.floor(timestamp / 60) - hours * 60;
  }
  if (Math.sign(minutes) === -1) {
    minutes = Math.abs(minutes);
    if (hours === 0) hours = '-'.concat(hours.toString());
  }
  if (result ? hours || minutes : true)
    return toExport ? hours * 60 + minutes : `${hours}h ${minutes}m`;
  else return;
};
const formattedDay = (value, format = 'ddd D MMM', toExport = false) => {
  return toExport ? day.getFormattedDate(value, format) : `${day.getFormattedDate(value, format)}.`;
};

export const parseIrrigationDay = date => {
  return day.getFormattedDate(date, 'dddd[,] D [de] MMMM');
};
export const startDayOfTheWeek = weekStartDay => {
  let date = null;
  if (currentDayIsEqualToStartDay()) {
    date = day.getFormattedDate('YYYY-MM-DD');
  } else {
    date = day.getDateByDay(weekStartDay);

    if (day.get('day', date) > day.get('day')) {
      date = day.getSubtractDateDays(date, 7, 'YYYY-MM-DD');
    }
  }

  return date;
};

const calculateVariation = (sumVolume, itemVolume, lang) => {
  const result = itemVolume - sumVolume;
  const variationInVol = `${helpers.formatNumber(result, lang, 2)} l/m²`;

  let variationInPer = 0;
  if (itemVolume !== 0) {
    const result = ((itemVolume - sumVolume) / sumVolume) * 100;
    variationInPer = `${helpers.formatNumber(result, lang, 2)}%`;
  }

  return variationInVol + '<br>' + variationInPer;
};

const currentPositionDay = () => {
  return day.get('day');
};
const currentDayIsEqualToStartDay = weekStartDay => {
  return currentPositionDay() === weekStartDay;
};
const getFinishDaysAndVisibilty = (crop, startDate) => {
  let days = 0;
  let visibility = true;
  let today = day.getToday('YYYY-MM-DD');
  let ciclos = crop?.ciclos;
  const isMultiyear = crop?.grupo_cultivo_id === 2;
  if (!isMultiyear) {
    if (ciclos) {
      let fechaSiembra = day.getFormattedDate(crop.fecha_siembra);
      let fechaInicio = day.getFormattedDate(new Date(startDate).toISOString());
      if (fechaInicio <= fechaSiembra) {
        visibility = false;
      } else {
        visibility = true;
        let fechaFinal = day.getFormattedDate(fechaSiembra, 'YYYY-MM-DD', {
          type: 'day',
          sum: ciclos
        });
        let diaFin = day.getDaysBetween(fechaFinal, today) - 1;
        days = parseInt(diaFin) >= 0 ? parseInt(diaFin) : -1;
      }
    } else {
      days = -1;
    }
  } else {
    days = 0;
  }

  return { days, visibility };
};
const reduceKey = (result, key) => {
  return result.reduce((a, b) => a + b[key], 0);
};
const filterArrayByDateRange = (result, ini, end) => {
  return result.filter(item => item.fecha >= parseInt(ini) && item.fecha <= parseInt(end));
};
const getAlertObject = (text, icon, type = 'success') => {
  return {
    text: text,
    icon: {
      color: 'green',
      name: icon
    },
    type: type
  };
};
const irrigationPropNames = (isGreenHouse = false) => ({
  riego:
    !isGreenHouse &&
    (globalStorage.get(enums.STORAGE_WATER_BALANCE_ENABLED) || env.APP_FORCED_NEW_HYDRIC_BALANCE)
      ? 'RiegoBrutoBalance'
      : 'RiegoBruto',
  min:
    !isGreenHouse &&
    (globalStorage.get(enums.STORAGE_WATER_BALANCE_ENABLED) || env.APP_FORCED_NEW_HYDRIC_BALANCE)
      ? 'MinRiegoBalance'
      : 'Min'
});
const getInfoPlans = (crop, startDate, isFertil, todayElement = {}) => {
  const infoPlan = {};
  const { days, visibility } = getFinishDaysAndVisibilty(crop, startDate);
  infoPlan.setFinishDate = day.getFormattedDate(
    day.addTime(new Date(crop.fecha_siembra), crop.ciclos),
    'DD/MM/YYYY'
  );
  infoPlan.setStartDate = day.getFormattedDate(
    new Date(crop.fecha_siembra).toISOString(),
    'DD/MM/YYYY'
  );
  infoPlan.startDayPlan = day.getFormattedDate(startDate, 'DD/MM/YYYY');
  infoPlan.days = days;
  infoPlan.visibility = visibility;
  infoPlan.isMultiyear = crop?.grupo_cultivo_id === 2;
  infoPlan.watchDailyTable =
    isFertil || todayElement.fecha === undefined
      ? false
      : day.getToday('ddd D MMM') + '.' === formattedDay(todayElement.fecha.toString());
  return infoPlan;
};
export const getDatesSigns = (crop, startDate, alertsText, isFertil, todayElement = {}) => {
  const signs = {};
  const infoPlan = getInfoPlans(crop, startDate, alertsText, isFertil, todayElement);
  if (infoPlan.days >= 0) {
    signs.remainingDays = !infoPlan.isMultiyear
      ? infoPlan.days === 0
        ? `${alertsText.todayFinish}`
        : `${alertsText.remainingDays} ${infoPlan.days} ${infoPlan.days > 1 ? 'días' : 'día'} `
      : `${alertsText.startPlan} ${infoPlan.startDayPlan}`;

    if (!infoPlan.isMultiyear && infoPlan.visibility)
      signs.rangeDate = `${infoPlan.setStartDate} - ${infoPlan.setFinishDate}`;
  }
  return signs;
};

export const getAlerts = (crop, startDate, alertsText, isFertil, todayElement = {}) => {
  const alerts = [];
  const infoPlan = getInfoPlans(crop, startDate, alertsText, isFertil, todayElement);
  if (infoPlan.days >= 0) {
    alerts.push(
      getAlertObject(
        !infoPlan.isMultiyear
          ? infoPlan.days === 0
            ? `<strong> ${alertsText.todayFinish} - (${infoPlan.setStartDate} - ${infoPlan.setFinishDate})</strong> `
            : `<strong> ${alertsText.remainingDays} ${infoPlan.days} ${
                infoPlan.days > 1 ? 'días' : 'día'
              } - (${infoPlan.setStartDate} - ${infoPlan.setFinishDate})</strong> `
          : ` <strong> ${alertsText.startPlan} ${infoPlan.startDayPlan} </strong>`,
        'calender'
      )
    );
  }
  if (!infoPlan.isMultiyear && !isFertil && infoPlan.visibility && infoPlan.watchDailyTable) {
    let message = '';
    if (infoPlan.days >= 0 && !infoPlan.isMultiyear) {
      let passedDays = day.getDaysBetween(day.getToday('YYYY-MM-DD'), crop.fecha_siembra);
      message = `${alertsText.activeCrop} <strong>${passedDays}</strong> ${
        passedDays > 1 ? 'días' : 'día'
      }`;
    } else if (!infoPlan.isMultiyear) {
      message = alertsText.wateringPlanFinished;
    }
    alerts.push(getAlertObject(message, 'clock'));
  }
  if (!infoPlan.isMultiyear && infoPlan.days < 0) {
    alerts.push(getAlertObject(alertsText.finishedPlan, 'clock'));
  }
  return alerts;
};
export const generateNextWeekIrrigationForecastTable = (
  data,
  lang,
  init,
  isGreenHouse,
  description
) => {
  const firstDayNextWeek = day.getFormattedDate(startDayOfTheWeek(init), 'YYYYMMDD', {
    type: 'day',
    sum: 7
  });
  const lastDayNextWeek = day.getFormattedDate(startDayOfTheWeek(init), 'YYYYMMDD', {
    type: 'day',
    sum: 13
  });
  const arr = filterArrayByDateRange(data, firstDayNextWeek, lastDayNextWeek);
  const time = reduceKey(arr, irrigationPropNames(isGreenHouse).min);
  const volume = reduceKey(arr, irrigationPropNames(isGreenHouse).riego);
  const item = [
    {
      description: description,
      time: formattedTime(time),
      volume:
        helpers.formatNumber(volume, lang, 2) == 0 ? '-' : helpers.formatNumber(volume, lang, 2)
    }
  ];
  return item;
};
export const generateTwoWeeksIrrigationTable = async (data, lang) => {
  const today = day.getToday('YYYYMMDD');
  const lastDayNextWeek = day.getFormattedDate(today, 'YYYYMMDD', {
    type: 'day',
    sum: 15
  });
  const items = [];
  const twoWeeks = await filterArrayByDateRange(data, today, lastDayNextWeek);
  const irrigationToday = twoWeeks.some(
    el => el.fecha === parseInt(today) && el != null && el[irrigationPropNames().riego] > 0
  );
  twoWeeks
    .filter(item => item != null && item[irrigationPropNames().riego] > 0)
    .forEach(item => {
      item.fecha == today
        ? items.push(formatJsonTable(item, lang, 'blue'))
        : items.push(formatJsonTable(item, lang));
    });

  return {
    irrigationToday,
    data: items,
    today: day.getToday('D [de]  MMMM')
  };
};
export const generateDailyAdjustmentTable = (
  data,
  irrigationPlanDates,
  lang,
  texts,
  isGreenHouse,
  forceIrrigationDiary = false
) => {
  const today = day.getToday('YYYYMMDD');
  const startDate = irrigationPlanDates.initDay.replace(/\//g, '');
  let indexToday = null;
  const findFunc = date => {
    return data.find((item, index) => {
      if (item.fecha === parseInt(date)) {
        indexToday = index;
        return item;
      }
    });
  };
  const element = findFunc(today) || findFunc(startDate);
  if (element?.VerSemana === 1) {
    const items = [];
    if (isGreenHouse || forceIrrigationDiary) {
      //Tabla de riego en el dashboard cuando el sistema es bajo invernadero (15)
      const variation = calculateVariation(
        element ? element.RiegoBrutoSemanalIni : 0,
        element ? element.RiegoBrutoSemanal : 0,
        lang
      );
      items.push(
        {
          day: texts.days[0],
          time: { isBold: true, text: element ? formattedTime(element.MinSemanal) : 0 },
          volume: {
            isBold: true,
            text: element ? `${helpers.formatNumber(element.RiegoBrutoSemanal, lang, 2)} l/m²` : 0
          }
        },
        {
          day: texts.days[1],
          time: element ? formattedTime(element.MinSemanalIni) : 0,
          volume: element
            ? `${helpers.formatNumber(element.RiegoBrutoSemanalIni, lang, 2)} l/m²`
            : 0
        },
        {
          day: texts.days[2],
          time: element ? formattedTime(element.MinSemanal - element.MinSemanalIni) : 0,
          volume: variation
        }
      );
    } else {
      //Tabla de riego en el dashboard cuando el sistema es diferente bajo invernadero (15)
      items.push(formatJsonTable(element, lang, 'blue'));
      for (let i = indexToday + 1; i < indexToday + 7; i++) {
        if (data[i] != null && data[i][irrigationPropNames(isGreenHouse).riego]) {
          items.push(formatJsonTable(data[i], lang));
        }
      }
    }
    return {
      title: texts.title,
      description: isGreenHouse
        ? `${texts.description}: ${parseIrrigationDay(
            startDayOfTheWeek(irrigationPlanDates.initWeek),
            lang
          )}`
        : '',
      header: texts.header,
      data: items,
      isGreenHouse
    };
  } else {
    return [];
  }
};
const formattedZeroValues = (element, property, lang, result = '-', toExport = false) => {
  return element && element[property] != null
    ? (element[property] === -999
        ? result
        : element[property]?.toLocaleString(lang, {
            maximumFractionDigits: 2
          }) == 0
        ? result
        : toExport
        ? element[property]
        : element[property]?.toLocaleString(lang, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })) ?? result
    : result;
};

const formatJsonTable = (element, lang, color) => {
  return {
    day: {
      color: color ?? 'gray-dark',
      text: helpers.firstLetterUppercase(day.getFormattedDate(element.fecha.toString(), 'dddd, D'))
    },
    volume: {
      color: color ?? 'gray-dark',
      text:
        element[irrigationPropNames().riego] && element[irrigationPropNames().riego] !== -999
          ? `${element[irrigationPropNames().riego].toLocaleString(lang, {
              maximumFractionDigits: 2
            })} l/m²`
          : '-'
    },
    time: {
      color: color ?? 'gray-dark',
      text: element[irrigationPropNames().min]
        ? formattedTime(element[irrigationPropNames().min])
        : '-'
    },
    precipitation: {
      color: color ?? 'gray-dark',

      text: element.ProbabilidadPrecipitacion
        ? `${element.ProbabilidadPrecipitacion.toLocaleString(lang, {
            maximumFractionDigits: 0
          })}%`
        : '-'
    },
    efectiva: {
      color: color ?? 'gray-dark',
      text:
        element.PrecipitacionEfectiva > 0
          ? `${helpers.formatNumber(element.PrecipitacionEfectiva, lang, 2)} l/m²`
          : '-'
    }
  };
};

const checkProductionDate = element => {
  return (
    new Date(day.getFormattedDate(element.fecha.toString(), 'YYYY-MM-DD')) >=
    new Date(enums.DEPLOY_DATE_SOIL_BALANCE)
  );
};

export const generateCalendarData = (elements, lang, weather) => {
  const data = [];
  const formattedData = element => day.getFormattedDate(element.fecha.toString(), 'YYYY-MM-DD');
  const setterRainIcon = element => {
    const weatherFound = weather.find(wd => wd.date === formattedData(element));
    if (weatherFound) return weatherFound.icon.toString();
    else {
      const numberRainEffective = {
        number: element['PrecipitacionEfectiva'],
        defined: formattedZeroValues(element, 'PrecipitacionEfectiva', lang, null) != null
      };
      const numberRainProbability = {
        number: element['ProbabilidadPrecipitacion'],
        defined: formattedZeroValues(element, 'ProbabilidadPrecipitacion', lang, null) != null
      };
      if (numberRainEffective.defined) {
        if (
          numberRainEffective.number != null &&
          numberRainEffective.number >= 0 &&
          !isNaN(+numberRainEffective.number)
        ) {
          const setRange = (first, second) =>
            numberRainEffective.number < first && numberRainEffective.number >= second;
          if (numberRainEffective.number === 60) return '4';
          else if (setRange(60, 30.1)) return '40';
          else if (setRange(30.1, 15.1)) return '12';
          else if (setRange(15.1, 2.1)) return '11';
          else if (setRange(2.1, 0.1)) return '26';
          else return '32';
        }
      } else if (numberRainProbability.defined) {
        if (numberRainProbability.number && !isNaN(+numberRainProbability.number)) {
          const percentage = numberRainProbability.number.toFixed();
          const setRange = (first, second) => percentage < first && percentage >= second;
          if (percentage === 100) return '4';
          else if (setRange(100, 75)) return '40';
          else if (setRange(75, 50)) return '12';
          else if (setRange(50, 25)) return '11';
          else if (setRange(25, 0) || lmRain) return '26';
          else if (!lmRain) return '32';
        }
      } else {
        return numberRainEffective.defined ? '26' : '32';
      }
    }
  };
  elements.forEach((element, index) => {
    const formattedElement = {
      date: formattedData(element),
      ...(formattedZeroValues(element, irrigationPropNames().riego, lang, null) && {
        volume: `${formattedZeroValues(element, irrigationPropNames().riego, lang)} l/m²`
      }),
      ...(parseNumberPercentage(element['ProbabilidadPrecipitacion'], null) && {
        rainPercentage: parseNumberPercentage(element['ProbabilidadPrecipitacion'])
      }),
      ...(formattedZeroValues(element, 'PrecipitacionEfectiva', lang, null) && {
        rainLM: `${formattedZeroValues(element, 'PrecipitacionEfectiva', lang)} l/m²`
      }),
      rainIcon: setterRainIcon(element, index),
      ...(element[irrigationPropNames().min] !== -999 && {
        time: formattedTime(element[irrigationPropNames().min], true)
      })
    };
    data.push(formattedElement);
  });
  return data;
};

export const generateChartData = elements => {
  const elementsByDi = [];
  const maxAFA = Math.max(...elements.map(c => c['AFA']));
  const setValue = (val, toNull = false) =>
    val && !isNaN(+val) && val !== -999 ? val : toNull ? null : 0;
  elements
    .filter(element => checkProductionDate(element))
    .forEach(c => {
      if (setValue(c[irrigationPropNames().riego])) {
        if (setValue(c['PrecipitacionEfectiva'])) {
          elementsByDi.push({ ...c, Di: c['AFA'] }, { ...c, PrecipitacionEfectiva: 0, Di: 0 });
        } else {
          elementsByDi.push({ ...c, Di: c['AFA'] }, { ...c, Di: 0 });
        }
      } else if (setValue(c['Di']) && setValue(c['PrecipitacionEfectiva'])) {
        elementsByDi.push(c, {
          ...c,
          PrecipitacionEfectiva: 0,
          Di: c['Di'] >= c['PrecipitacionEfectiva'] ? c['Di'] - c['PrecipitacionEfectiva'] : 0
        });
      } else {
        elementsByDi.push(c);
      }
    });
  const xaxis = elementsByDi.map(c => day.getFormattedDate(c.fecha.toString(), 'DD-MM-YYYY'));
  return {
    xaxis,
    data: elementsByDi.map((c, i) => {
      return {
        AFA: {
          key: 'Riesgo de estrés hídrico',
          value: maxAFA * 1.5,
          type: 'scatter',
          showlegend: false,
          mode: { text: 'none' },
          hover: {
            text: '%{x}<extra></extra>'
          },
          fill: { text: 'tozeroy', color: '#ffe5e5' }
        },
        AFA2: {
          key: 'Contenido de agua adecuado',
          value: setValue(c['AFA']),
          type: 'scatter',
          showlegend: false,
          mode: { text: 'none' },
          fill: { text: 'tozeroy', color: '#e4f2e6' }
        },
        AFA3: {
          key: 'AFA: Agua fácilmente aprovechable',
          value: setValue(c['AFA']),
          showlegend: false,
          type: 'scatter',
          hover: {
            text: '<b>%{y:.2f} - %{x}</b> | AFA: Agua fácilmente aprovechable<extra></extra>'
          },
          mode: { text: 'lines', color: '#666666' }
        },
        Di: {
          key: 'Agua del suelo extraída por el cultivo',
          value: setValue(c['Di']),
          type: 'scatter',
          hover: {
            text: '<span style="color: white"><b>%{y:.2f} - %{x}</b> | Agua del suelo extraída por el cultivo</span><extra></extra>'
          },
          mode: { text: 'lines', color: '#4CB5D1' }
        },
        RiegoNeto: {
          key: 'Riego neto',
          value: setValue(c['RiegoNeto'], true),
          type: 'scatter',
          showlegend: false,
          hover: { text: '<b>%{y:.2f} <i>l/m²</i> - %{x}</b>' },
          mode: { text: 'lines', color: '#109DC2' }
        },
        [irrigationPropNames().riego]: {
          key: 'Volumen de riego',
          value: setValue(c[irrigationPropNames().riego], true),
          type: 'bar',
          yaxis: 'y2',
          xaxis: 'x2',
          width: 0.45,
          hover: {
            text: '<b>%{y:.2f} <i>l/m²</i> - %{x}</b> | Volumen de riego<extra></extra>'
          },
          mode: { text: 'lines', color: '#b4efff' }
        },
        PrecipitacionEfectiva: {
          key: 'Precipitación',
          value: setValue(c['PrecipitacionEfectiva']),
          type: 'bar',
          yaxis: 'y2',
          xaxis: 'x2',
          width: 0.45,
          hover: {
            text: '%{y:.2f} <i>l/m²</i> - %{x}'
          },
          mode: { text: 'lines', color: '#0B7591' }
        },
        hided: { date: xaxis[i] }
      };
    })
  };
};

export const generateIrrigationForecastTable = (
  elements,
  lang,
  title,
  header,
  isGreenHouse,
  toExport = false
) => {
  const data = [];
  elements.forEach((element, index) => {
    const today = element.fecha.toString() === day.getToday('YYYYMMDD');
    const formattedElement = {
      day: {
        text: element
          ? formattedDay(element.fecha.toString(), toExport ? 'DD/MM/YYYY' : 'ddd D MMM', toExport)
          : ''
      },
      time: {
        text: element
          ? element[irrigationPropNames(isGreenHouse).min] === -999
            ? '-'
            : formattedTime(element[irrigationPropNames(isGreenHouse).min], false, toExport)
          : '0h 0m'
      },
      volume: {
        text:
          formattedZeroValues(element, irrigationPropNames(isGreenHouse).riego, lang) !== '-'
            ? toExport
              ? formattedZeroValues(
                  element,
                  irrigationPropNames(isGreenHouse).riego,
                  lang,
                  '-',
                  toExport
                )
              : `${formattedZeroValues(
                  element,
                  irrigationPropNames(isGreenHouse).riego,
                  lang,
                  '-',
                  toExport
                )} l/m²`
            : toExport
            ? 0
            : '-'
      },
      ['ProbabilidadPrecipitacion']: {
        text: parseNumberPercentage(element['ProbabilidadPrecipitacion'], '-', toExport)
      },
      pre: {
        text:
          formattedZeroValues(element, 'PrecipitacionEfectiva', lang) !== '-'
            ? toExport
              ? formattedZeroValues(element, 'PrecipitacionEfectiva', lang, '-', toExport)
              : `${formattedZeroValues(element, 'PrecipitacionEfectiva', lang, '-', toExport)} l/m²`
            : toExport
            ? 0
            : '-'
      }
    };
    if (today) scrollTo = index;
    data.push(formattedElement);
  });
  const table = {
    title,
    header,
    data,
    mensajeError: elements[elements.length - 1]?.Mensaje ?? 'error',
    isGreenHouse
  };
  return { table, scrollTo };
};
export const showGeneratedPlan = (element, crop, startDate, futurePlan = false) => {
  const isMultiYear = crop?.grupo_cultivo_id === 2;
  const watchDailyTable =
    day.getToday('ddd D MMM') + '.' === formattedDay(element?.fecha?.toString());
  const days = isMultiYear ? 0 : getFinishDaysAndVisibilty(crop, startDate).days;
  return days >= 0 && watchDailyTable && (isMultiYear || thereIsDataToLoad(crop)) && !futurePlan;
};
export const parseNumberPercentage = (number, result = '-', toExport = false) => {
  if (number !== null && number !== undefined && number !== 0) {
    return toExport ? number.toFixed() : `${number.toFixed()}%`;
  }
  return toExport ? 0 : result;
};
export const formatNumberValues = (array, except = undefined) => {
  let arrayResult = [];
  if (array.length > 0) {
    arrayResult = array.map(item => {
      for (const property in item) {
        if (
          (typeof item[property] === 'number' && except !== property) ||
          (isNumber(item[property]) && except !== property)
        ) {
          item[property] =
            typeof item[property] === 'string'
              ? formatFloat(item[property])
              : formatNumber(item[property]);
        }
      }
      return item;
    });
    return arrayResult;
  } else return array;
};

const isNumber = value => (typeof value === 'string' ? !isNaN(value.replace(',', '')) : false);

const formatNumber = value => {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2
  }).format(value.toFixed(2));
};
const formatFloat = value => {
  const floatValue = parseFloat(value.replace(',', '.'));
  return formatNumber(floatValue);
};
