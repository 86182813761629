import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../assets/exports/Ubuntu-Regular-normal';

const confHeaderExcel = { origin: 'A2', skipHeader: true };

export const xlsx = (data, summaryData, titleSummary, nameFile) => {
  const formatedData = replacesWithDash(data);
  const wb = XLSX.utils.book_new();
  if (summaryData && titleSummary) {
    const summary = XLSX.utils.json_to_sheet(summaryData, { skipHeader: 1 });
    XLSX.utils.book_append_sheet(wb, summary, titleSummary);
  }
  formatedData.forEach(element => {
    const sheet = XLSX.utils.json_to_sheet(getFormatedNumbers(element.data), confHeaderExcel);
    XLSX.utils.sheet_add_aoa(sheet, element.header);
    XLSX.utils.book_append_sheet(wb, sheet, element.sheetTitle);
  });

  XLSX.writeFile(wb, `${nameFile}.xlsx`);
};
export const pdf = (data, summaryData, titleSummary, nameFile) => {
  const formatedData = replacesWithDash(data);
  const doc = new jsPDF();
  doc.setFont('Ubuntu-Regular', 'normal');
  doc.addImage(require('../../static/logo.png'), 'PNG', 14, 10, 22, 6);
  doc.setFontSize(15);
  if (titleSummary) doc.text(titleSummary, 15, 30);
  if (summaryData) {
    doc.autoTable({
      drawHeader: false,
      body: summaryData.map(e => Object.values(e)),
      startY: 35
    });
  }

  formatedData.forEach(element => {
    const space = summaryData ? doc.autoTable.previous.finalY - 5 : 20;
    doc.text(element.pdfColumnTitle, 15, space + 15);
    doc.autoTable(getConfAutotable(space, element.header, element.data));
  });

  doc.save(`${nameFile}.pdf`);
};
const getConfAutotable = (space, header, data) => {
  return {
    head: getHeaderWithoutSubscript(header),
    body: getFormatedDataToPdf(data),
    headStyles: {
      fillColor: '#109DC2'
    },
    bodyStyles: {
      textColor: [102, 102, 102]
    },
    alternateRowStyles: {
      fillColor: '#EAEAEA'
    },
    startY: space + 21
  };
};
const getFormatedDataToPdf = data => {
  return data.map(element => {
    const formatNumbers = Object.keys(element).map(key =>
      typeof element[key] === 'number' ? formatedNumberPdf(element[key]) : element[key]
    );
    return formatNumbers;
  });
};

const formatedNumberPdf = value => {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2
  }).format(value.toFixed(2));
};

const getHeaderWithoutSubscript = str => {
  const superSubScriptDict = {
    '\u2070': '0',
    '\u00B9': '1',
    '\u00B2': '2',
    '\u00B3': '3',
    '\u2074': '4',
    '\u2075': '5',
    '\u2076': '6',
    '\u2077': '7',
    '\u2078': '8',
    '\u2079': '9',
    '\u2080': '0',
    '\u2081': '1',
    '\u2082': '2',
    '\u2083': '3',
    '\u2084': '4',
    '\u2085': '5',
    '\u2086': '6',
    '\u2087': '7',
    '\u2088': '8',
    '\u2089': '9'
  };

  const regex = new RegExp('[' + Object.keys(superSubScriptDict).join('') + ']', 'g');
  return str.length > 0 ? [str[0].map(e => e.replace(regex, x => superSubScriptDict[x]))] : [];
};
const getFormatedNumbers = data => {
  const dataToExport = JSON.parse(JSON.stringify(data));
  dataToExport.forEach(e => {
    Object.keys(e).forEach(key => {
      if (typeof e[key] === 'string' && checkIsStringNumber(e[key]))
        e[key] = getTransformedStringToFloat(e[key]);
      else if (typeof e[key] === 'number') e[key] = parseFloat(getNumberWithTwoDecimals(e[key]));
    });
  });
  return dataToExport;
};
const checkIsStringNumber = strNumber =>
  /^-?\d+$/.test(strNumber.replace('.', '').replace(',', ''));

const getTransformedStringToFloat = strNumber =>
  parseFloat(parseFloat(strNumber.replace('.', '').replace(',', '.')).toFixed(2));

const getNumberWithTwoDecimals = number => {
  return number.toFixed(2);
};
const replacesWithDash = data => {
  let items = data;
  items.forEach(e => {
    if (e.data.constructor === Array) {
      e.data.forEach(o => {
        Object.keys(o).forEach(prop => {
          if (o[prop] === -999) {
            o[prop] = '-';
          }
        });
      });
    } else if (typeof items === 'object') {
      Object.keys(e.data).forEach(prop => {
        if (e.data[prop] === -999) {
          e.data[prop] = '-';
        }
      });
    }
  });

  return items;
};
export default {
  xlsx,
  pdf
};
