// String transform
export const firstLetterUppercase = val =>
  !val ? '' : `${val.toString().charAt(0).toUpperCase()}${val.toString().slice(1)}`;

export const trimAndUppercase = val => (val ? val.replace(/\s/g, '').trim().toUpperCase() : '');

export const uppercase = val => (val ? val.toUpperCase() : '');

export const roundPrice = val => (val ? val.toFixed(2).toString().replace('.', ',') : '');

export const capitalize = val => {
  if (!val) return '';
  return val
    .toString()
    .replace(/[A-zÀ-ú0-9]\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const pascalToKebab = str =>
  str.replace(/\.?([A-Z]+)/g, ($1, $2) => `-${$2.toLowerCase()}`).replace(/^-/, '');
