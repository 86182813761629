import { day, helpers, globalStorage } from '@pt/utilities';
import { enums } from '@pt/env-config';
import { api } from '@pt/services';

export default function () {
  const getHeaderToday = (t, toExport = false) => {
    const todayHeader = [
      { value: t('plans.day'), key: 'day' },
      { value: toExport ? t('plans.timeExport') : t('plans.time'), key: 'time' },
      { value: toExport ? t('plans.volumeExport') : t('plans.volume'), key: 'volume' },
      {
        value: toExport ? t('plans.rainProbabilityExport') : t('plans.rainProbability'),
        key: 'ProbabilidadPrecipitacion'
      },
      {
        value: toExport ? t('plans.precipitationEfectExport') : t('plans.precipitationEfect'),
        key: 'pre'
      }
    ];

    return todayHeader.map(e => e.value);
  };
  const getGeneratedDailyTable = (predictions, t, locale, isGreenHouse, toExport = false) => {
    const todayTitle = t('plans.yourIrrigationPlanToday');
    return helpers.generateIrrigationForecastTable(
      predictions,
      locale,
      todayTitle,
      getHeaderToday(t, toExport),
      isGreenHouse,
      toExport
    );
  };
  const getCalendarData = async (predictions, locale) => {
    const storageNavbar = globalStorage.get(enums['STORAGE_NAVBAR']);
    const coordinates = {
      lat: storageNavbar.farm?.latitud,
      lng: storageNavbar.farm?.longitud
    };
    return await api.getWeather(coordinates.lat, coordinates.lng).then(res => {
      const weatherIcons = res.forecast.days.map(day => ({
        icon: day.iconCode,
        date: `${day.year}-${day.month}-${day.day}`
      }));
      return helpers.generateCalendarData(predictions, locale, weatherIcons);
    });
  };
  const getChartData = predictions => {
    return helpers.generateChartData(predictions);
  };
  const getWeeklyText = (irrigationPlanDates, t) => {
    const weeklyText = {
      days: [
        {
          isBold: true,
          text: t('plans.AdjustedWeeklyIrrigation'),
          description: t('plans.totalWeeklyWatering')
        },
        {
          isBold: false,
          text: `${t('plans.weeklyIrrigationForecast')} (${day.getFormattedDate(
            helpers.startDayOfTheWeek(irrigationPlanDates.initWeek),
            'D MMMM'
          )})`,
          description: t('plans.weeklyWateringIndicated')
        },
        {
          isBold: false,
          text: t('plans.cumulativeVariation'),
          description: t('plans.accumulatedVariationText')
        }
      ],
      title: t('plans.weeklyIrrigationForecastTitle'),
      description: t('plans.wateringDay'),
      header: [
        'Semana del ' +
          day.getFormattedDate(helpers.startDayOfTheWeek(irrigationPlanDates.initWeek), 'DD') +
          ' al ' +
          day.getFormattedDate(helpers.startDayOfTheWeek(irrigationPlanDates.initWeek), 'DD MMMM', {
            type: 'day',
            sum: 6
          }),

        t('plans.time'),
        t('plans.volume')
      ]
    };
    return weeklyText;
  };
  const getGeneratedWeeklyTable = (predictions, irrigationPlanDates, t, locale, isGreenHouse) => {
    return helpers.generateDailyAdjustmentTable(
      predictions,
      irrigationPlanDates,
      locale,
      getWeeklyText(irrigationPlanDates, t),
      isGreenHouse
    );
  };

  const getGeneratedNextWeekHeaderText = irrigationPlanDates => {
    return [
      'Semana del ' +
        day.getFormattedDate(helpers.startDayOfTheWeek(irrigationPlanDates.initWeek), 'DD', {
          type: 'day',
          sum: 7
        }) +
        ' al ' +
        day.getFormattedDate(helpers.startDayOfTheWeek(irrigationPlanDates.initWeek), 'DD MMMM', {
          type: 'day',
          sum: 13
        }),
      'Tiempo',
      'Volumen'
    ];
  };
  const getGenerateNextWeek = async (predictions, locale, initWeek, isGreenHouse, text) => {
    const nextWeekTable = await helpers.generateNextWeekIrrigationForecastTable(
      predictions,
      locale,
      initWeek,
      isGreenHouse,
      text
    );
    nextWeekTable.forEach(el => (el.volume = `${el.volume} l/m²`));
    return nextWeekTable;
  };
  const changeDataWeeklyToExport = (data, irrigationPlanDates) => {
    if (data.weekly.data) {
      data.weekly.data[0].day = 'Riego semanal ajustado (hoy)';
      data.weekly.data[1].day =
        'Previsión (' +
        day.getFormattedDate(helpers.startDayOfTheWeek(irrigationPlanDates.initWeek), 'DD MMMM') +
        ')';
      data.weekly.data[2].day = 'Variación acumulada';
    }

    return data;
  };
  return {
    getChartData,
    getCalendarData,
    getGenerateNextWeek,
    getGeneratedDailyTable,
    getGeneratedWeeklyTable,
    changeDataWeeklyToExport,
    getGeneratedNextWeekHeaderText
  };
}
