import { day } from '@pt/utilities';

export default function () {
  const getDataTableToExportIrrigation = data => {
    const dataToExport = JSON.parse(JSON.stringify(data));
    const tablesData = [];
    tablesData.push({
      data: getFormateData(dataToExport.daily.table.data),
      header: [dataToExport.daily.table.header],
      sheetTitle: 'Plan de riego diario',
      pdfColumnTitle: 'Plan de riego diario'
    });
    if (dataToExport.weekly.data) {
      tablesData.push({
        data: getFormateData(dataToExport.weekly.data),
        header: [dataToExport.weekly.header],
        sheetTitle: 'PDR semanal',
        pdfColumnTitle: 'Previsión de riego semanal'
      });
    }
    if (dataToExport.nextWeek.data)
      tablesData.push({
        data: getFormateData(dataToExport.nextWeek.data),
        header: [dataToExport.nextWeek.header],
        sheetTitle: 'PDR para la próxima semana',
        pdfColumnTitle: 'Previsión de riego para la próxima semana'
      });
    return tablesData;
  };
  const removeHtmlTag = data => {
    data.forEach(element => {
      Object.keys(element).forEach(key => {
        if (typeof element[key] === 'string') {
          element[key] = element[key].replace('<br>', '/');
          element[key] = element[key].replace(/(<([^>]+)>)/gi, '');
        }
      });
    });
    return data;
  };
  const getFormateData = data => {
    data.forEach(element => {
      if (element) {
        Object.keys(element).forEach(key => {
          if (element[key].text !== undefined) {
            element[key] = element[key].text;
          }
        });
      }
    });
    return removeHtmlTag(data);
  };
  const getSummaryIrrigation = (generalData, downloadDate) => {
    return [
      {
        col1: `Explotación: ${generalData.exploitation.nombre} `,
        col2: `Fecha informe: ${downloadDate} `
      },
      { col1: `Finca:  ${generalData.farm.nombre}` },
      {
        col1: `Cultivo: ${generalData.crop.nombre} `,
        col2: `Sistema de cultivo:  ${generalData.crop.sistema_cultivo_nombre}`
      },
      { col1: `Sistema de riego:  ${generalData.crop.tipo_riego_nombre}` },
      {
        col1:
          generalData.crop.grupo_cultivo_id === 2
            ? `Edad: ${generalData.crop.edad} `
            : `Días cultivo: ${
                getCultivationDays(generalData.crop) < 0
                  ? 'El cultivo todavía no ha empezado'
                  : getCultivationDays(generalData.crop)
              } `,
        col2:
          generalData.crop.grupo_cultivo_id === 2
            ? ''
            : `Trasplante/Emergencia:  ${day.getFormattedDate(
                generalData.crop.fecha_siembra,
                'DD-MM-YYYY'
              )}`
      }
    ];
  };
  const getCultivationDays = crop => {
    const seedingDate = day.getFormattedDate(crop.fecha_siembra, 'YYYY-MM-DD');
    return day.getDaysBetween(day.getToday('YYYY-MM-DD'), seedingDate) + 1;
  };
  return {
    getDataTableToExportIrrigation,
    getSummaryIrrigation
  };
}
