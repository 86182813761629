import dayjs from 'dayjs';
import locale from 'dayjs/locale/es';
import weekdayPlugin from 'dayjs/plugin/weekday';
import objectPlugin from 'dayjs/plugin/toObject';
import isTodayPlugin from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(weekdayPlugin);
dayjs.extend(objectPlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(utc);
dayjs.extend(duration);

const lang = document.querySelector('html').getAttribute('lang') || 'es';

dayjs.locale(lang);

export const get = (unit, value = undefined) => dayjs(value).get(unit);

export const getNow = () => dayjs().locale({ ...{ locale } });

export const getFormattedDate = (data, format, add = { type: 'day', sum: 0 }) =>
  data ? dayjs(data).add(add.sum, add.type).format(format) : null;

export const getToday = (format = 'ddmmyyyy') => dayjs().format(format);

export const getObjectDate = date => dayjs(date).toObject();

export const getDaysBetween = (date1, date2) => {
  const d1 = dayjs(date1);
  return d1.diff(date2, 'day');
};

export const getSubtractDateDays = (date, days, format = 'DD-MM-YYYY') =>
  dayjs(date).subtract(days, 'days').format(format);

export const getSubtractDateHours = (date, days, format = 'HH') =>
  dayjs(date).subtract(days, 'h').format(format);

export const getDateByDay = (unit, value = undefined, format = 'YYYY-MM-DD') =>
  dayjs(value).day(unit).format(format);

export const isLessThanOneDay = val => {
  const now = dayjs().format('YYYY-MM-DDTHH:mm:ss');
  const notification = dayjs(val).format('YYYY-MM-DDTHH:mm:ss');
  return dayjs(now).diff(notification, 'days') <= 0;
};

export const differenceBetweenDatesWithText = val => {
  const getTimeText = (value, type) => {
    const time = Math.abs(value);
    let text = '';
    const texts = {
      month: 'mes',
      week: 'semana',
      day: 'día',
      hour: 'hora',
      minute: 'minuto'
    };
    if (type !== 'month') {
      text = time + ' ' + texts[type] + (time > 1 ? 's' : '');
    } else {
      text = time + ' ' + texts[type] + (time > 1 ? 'es' : '');
    }
    return text;
  };
  const now = dayjs().format('YYYY-MM-DDTHH:mm:ss');
  const notification = dayjs(val).utc().local().format('YYYY-MM-DDTHH:mm:ss');
  // get the difference between the moments
  const diff = dayjs(now).diff(notification);
  // express as a duration
  const diffDuration = dayjs.duration(diff);
  return diffDuration.months() > 0
    ? getTimeText(diffDuration.months(), 'month')
    : Math.abs(diffDuration.weeks()) > 0
    ? getTimeText(diffDuration.weeks(), 'week')
    : Math.abs(diffDuration.days()) > 0
    ? getTimeText(diffDuration.days(), 'day')
    : Math.abs(diffDuration.hours()) > 0
    ? getTimeText(diffDuration.hours(), 'hour')
    : Math.abs(diffDuration.minutes()) > 0
    ? getTimeText(diffDuration.minutes(), 'minute')
    : 'menos de un minuto';
};

export const addTime = (date, daysAdd, type = 'day') => dayjs(date).add(daysAdd, type);
