export default function () {
  const loadFormatedTables = (tables, isExtensive) => {
    const formatedTables = {
      tabla: formatUnits(getFormatedTable(tables['tabla'], isExtensive, 3), true, 'cantidad_kg'),
      tabla_mensual: getFormatedTable(tables['tabla_mensual']),
      tabla_uf: getFormatedTable(tables['tabla_uf']),
      tabla_uf_mensual: getFormatedTable(tables['tabla_uf_mensual']),
      reporte: getFormatedTable(tables['reporte']),
      necesidades_totales: getFormatedTable(tables['necesidades_totales']),
      total_abonos: formatUnits(
        getFormatedTable(tables['total_abonos'], true, 2),
        true,
        'cantidad'
      ),
      total_uf: getFormatedTable(tables['total_uf'])
    };
    return formatedTables;
  };
  const getFormatedTable = (table, format = false, slice) => {
    if (table) {
      table.header =
        slice && format
          ? table.headers.map(e => e.text).slice(0, slice)
          : table.headers.map(e => e.text);
      table = orderDataTableByHeader(table);
    } else {
      table = '';
    }
    return table;
  };

  const formatUnits = (table, format = false, element) => {
    if (table && format) {
      table.data.forEach(el => {
        if (el[element])
          el[element] = `${el[element].toLocaleString('es', {
            maximumFractionDigits: 2
          })} ${el.unidad}`;
      });
      table.data.forEach(el => delete el.unidad);
    }
    return table;
  };
  const orderDataTableByHeader = table => {
    if (table && Object.values(table).every(item => item)) {
      table.data.forEach((e, index) => {
        let aux = {};
        table.headers.forEach(header => {
          aux[header.value] = e[header.value];
        });
        table.data[index] = aux;
      });
      return table;
    }
  };

  return {
    loadFormatedTables,
    orderDataTableByHeader
  };
}
