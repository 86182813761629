import * as number from './number';
import * as object from './object';
import * as script from './script';
import * as string from './string';
import * as plans from './plans';

export default {
  ...number,
  ...object,
  ...script,
  ...string,
  ...plans
};
