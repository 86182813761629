import { io } from 'socket.io-client';
import { env } from '@pt/env-config';

export const init = async (url, token) => {
  const socket = io(url, {
    name: '',
    forceNew: true,
    path: '',
    transports: ['polling'],
    extraHeaders: {
      'Ocp-Apim-Subscription-Key': env.API_OCP_APIM_SUBSCRIPTION_KEY,
      'Ocp-Apim-Trace': env.APP_IS_PROD_ENV !== 'enabled',
      Authorization: token
    }
  });

  return socket;
};

export default {
  init
};
