import numeral from 'numeral';
import 'numeral/locales/es';

numeral.locale('es');

/**
 * Formates file size.
 *
 * @param {Number|String} size
 */
export const formatFileSize = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// Numbers format
const customFormat = val => (val.toString().includes('.') ? '0,0.00' : '0,0');
// export const number = val => numeral(val).format(customFormat(val));
export const number = (val, locale = '') => val.toLocaleString(locale);
export const currency = val => numeral(val).format(`${customFormat(val)} $`);
export const taxPercent = val => numeral(val).format('0 %');
export const formatNumber = (val, locale = 'es-ES', decimals = 2) => {
  if (val) {
    return typeof val === 'string'
      ? Number(val.replace(',', '.')).toLocaleString(locale, {
          maximumFractionDigits: decimals
        })
      : val.toLocaleString(locale, {
          maximumFractionDigits: decimals
        });
  } else return '';
};
